@import "../../include";

.partGetCashback {
  position: relative;
  padding: 200px 0;

  &__background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  &__content {
    @extend %container;
  }

  &__title {
    margin-bottom: 10px;

    font-family: Shoulders, sans-serif;
    font-size: 60px;
    font-weight: 900;
    line-height: 1.1;
    text-transform: uppercase;

    @media #{$phone} {
      font-size: 46px;
    }
  }

  &__text {
    max-width: 670px;
    font-size: 22px;
    line-height: 1.5;
  }

  @media #{$phone} {
    padding: 100px 0 60px;
  }
}
