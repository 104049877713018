@import "../../../include";

.PartFormInput {
  &__title {
    margin-bottom: 5px;
    font-size: 16px;
    color: #594057;
  }

  &__label {
    position: relative;

    display: block;

    padding: 0 20px;

    background: #fff;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
  }

  &__input {
    position: relative;
    z-index: 1;

    display: block;

    width: 100%;
    padding: 20px 0;

    font-size: 18px;
    font-weight: 500;
    color: #36162E;

    background: transparent;
    border: 0;

    transition: color .2s linear;

    -webkit-text-fill-color: #36162E; /* autocomplete text color */

    &::placeholder {
      font-size: 18px;
      font-weight: 500;
      color: #594057;
      opacity: .5;
    }

    &:focus {
      outline: 0;
    }
  }
}
