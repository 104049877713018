@import "../../include";

.partFooter {
  position: relative;

  &__BG {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
    }
  }

  &__content {
    @extend %container;

    display: flex;
    align-items: flex-end;

    height: 240px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__copyright {
    font-size: 16px;
    line-height: 1.6;
    color: #9D8E9A;
    opacity: .5;

    @media #{$phone} {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}
