@import "../../include";

.banner {
  position: relative;
  padding: 130px 0 220px;

  @media #{$phone} {
    padding: 42px 0 82px;
  }
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  padding: 3rem; 
  max-width: 38rem;
  border-radius: .4rem;
  z-index: 1000;
}

.serviceAreaLink {
  display: flex;
  margin: 1rem;
  align-items: center;
  text-decoration: none;
}

.serviceAreaIcon {
  width: 2rem;
  height: 2rem;
  margin-left: 2rem;
  margin-right: 10px;
  fill: #0C2E16;
  .serviceAreaLink:hover & {
    fill: #FF6D3F;
  }
}

.serviceAreaButton {
  margin: 0;
  font-size: 30px;
  padding-top: 0.2rem;
  font-weight: 500;
  line-height: 1.6;
  color: #0C2E16;
  .serviceAreaLink:hover  &{
    color: #FF6D3F;
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.popupContent {
  display: flex;
  align-items: center;
}

.popupIcon {
  margin-right: .5rem;
  margin-bottom: .5rem;
  width: 2.5rem;
  height: 2.85rem;
}

.serviceAreaTitle {
  font-size: 2rem;
  margin-bottom: .5rem;
}

.fw500 {
  font-weight: 500;
}

.fz20 {
  font-size: 1rem;
}

.lh16 {
  line-height: 1.6;
}

.bannerImage {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;

  &_desktop {
    @media #{$phone} {
      display: none;
    }
  }

  &_mobileMD {
    @media #{$tablet-and-desktop} {
      display: none;
    }

    @media #{$phone} {
      display: block;
    }

    @media (width <= 550px) {
      display: none;
    }
  }

  &_mobileSM {
    @media #{$tablet-and-desktop} {
      display: none;
    }

    @media #{$phone} {
      display: none;
    }

    @media (width <= 550px) {
      display: block;
    }
  }
}

.bannerContent {
  @extend %container;

  & > * {
    @media #{$phone} {
      max-width: 60%;
    }
  }

  h1 {
    font-family: Shoulders, sans-serif;
    font-size: 66px;
    font-weight: 900;
    line-height: 1.1;

    i {
      font-style: normal;
      color: #0c2e16;
    }

    @media #{$phone} {
      font-size: 31px;
    }
  }

  ul {
    margin: 5% 2% 0 7%;
    list-style-type: circle;

    li {
      font-size: 30px;
      font-weight: 300;
      line-height: 1.6;

      @media #{$phone} {
        font-size: 19px;
      }
    }
  }
}
