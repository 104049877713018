@import "../../include";

.partReplacementProcess {
  @extend %container;

  &__title {
    margin-top: 2em;
    margin-bottom: 40px;

    font-family: Shoulders, sans-serif;
    font-size: 52px;
    font-weight: 900;
    line-height: 1.1;
    text-transform: uppercase;

    @media #{$phone} {
      margin-bottom: 60px;
      font-size: 46px;
    }
  }

  &__videoBannerBox {
    position: relative;

    aspect-ratio: 1920 / 1080;
    width: 100%;
    margin-bottom: 60px;

    box-shadow: inset 0 0 12px 0 #f2f2f2;
  }

  &__videoBannerPromoImage {
    position: absolute;
    z-index: 2;
    top: -40px;
    left: 20px;

    width: 300px;

    @media #{$phone} {
      top: -60px;
      width: 165px;
    }
  }

  &__videoBanner {
    video {
      display: block;
      width: 100%;
    }
  }

  &__videoBannerFrameImage {
    position: absolute;
    top: -1px;
    left: -1px;

    display: block;

    width: 101%;
    max-width: 101%;
    height: 101%;
    max-height: 101%;
  }

  &__description {
    font-size: 22px;
    line-height: 1.5;

    p:not(:last-child) {
      margin-bottom: 60px;
    }
  }
}
