@import "../include";

.page {
  .headerContainer {
    @extend %container-full;
  }

  .firstScreenBannerContainer {
    @extend %container-full;

    @media #{$phone} {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .formContainer {
    margin-top: -180px;
    margin-bottom: 40px;


    @media #{$phone} {
      margin-top: -60px;
      margin-bottom: 80px;
    }
  }

  .socialRatingsContainer {
    margin-bottom: 100px;

    @media #{$phone} {
      margin-bottom: 80px;
    }
  }

  .cashbackContainer {
    @extend %container-full;

    @media #{$phone} {
      margin-bottom: 80px;
    }
  }

  .feedbacksContainer {
    margin-top: -140px;
    margin-bottom: 100px;

    @media #{$phone} {
      margin-top: 0;
      margin-bottom: 80px;
    }
  }

  .replacementProcessContainer {
    @extend %container-full;

    margin-bottom: 100px;

    @media #{$phone} {
      margin-bottom: 80px;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .whyReplaceContainer {
    margin-bottom: 100px;

    @media #{$phone} {
      margin-bottom: 80px;
    }
  }

  .windowTypesContainer {
    margin-bottom: 100px;

    @media #{$phone} {
      margin-bottom: 80px;
    }
  }

  .windowViewSliderContainer {
    @extend %container-full;

    margin-bottom: 100px;

    @media #{$phone} {
      margin-bottom: 80px;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .superiorTechnologyContainer {
    @extend %container-full;

    margin-bottom: 80px;

    @media #{$phone} {
      margin-bottom: 80px;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .getEstimateButton {
    cursor: pointer;

    position: fixed;
    z-index: 100;
    right: 20px;
    bottom: 20px;

    padding: 10px 15px;

    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;

    opacity: 1;
    background-color: #FF6D3F;
    border: none;
    border-radius: 5px;
    box-shadow: 0 27px 56px rgb(255 109 63 / 43%), 0 8.14px 16.88px rgb(255 109 63 / 28%), 0 3.38px 7.01px rgb(255 109 63 / 21.5%), 0 1.22px 2.53px rgb(255 109 63 / 15%), inset 0 12px 28px rgb(255 221 102 / 45%);

    transition: opacity 150ms linear, background-color 0.3s ease;

    &:hover {
      background-color: #FF7D5F;
    }

    &_hiden {
      width: 0;
      height: 0;
      padding: 0;

      font-size: 0;

      opacity: 0;
    }
  }
}
