@import "../../include";

.partSuperiorTechnology {
  @extend %container;

  position: relative;

  &::before, &::after {
    content: ' ';

    position: absolute;
    z-index: -1;

    width: 50vw;
    height: 480px;

    background: #F7F7F7;

    @media #{$phone} {
      display: none;
    }
  }

  &::before {
    right: 50%;
    bottom: 0;
  }

  &::after {
    top: 100px;
    left: 50%;
  }

  &__content {
    margin: 0 -4px;
    padding: 0 4px 100px;
    background: #fff;

    @media #{$phone} {
      margin: 0;
      padding: 0;
    }
  }

  &__title {
    margin-bottom: 20px;

    font-family: Shoulders, sans-serif;
    font-size: 52px;
    font-weight: 900;
    line-height: 1.3;
    text-transform: uppercase;

    @media #{$phone} {
      font-size: 46px;
    }
  }

  &__subTitle {
    max-width: 800px;
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 1.5;

    @media #{$phone} {
      font-size: 20px;
    }
  }

  &__technologiesList {
    display: flex;

    @media #{$phone} {
      flex-wrap: wrap;
    }
  }

  &__technologiesListItemContainer {
    width: 33.3333%;

    @media #{$phone} {
      width: 100%;

      &:not(&:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__technologiesListItem {
    padding-right: 2px;
    padding-left: 2px;

    @media #{$phone} {
      padding: 0;
    }
  }

  &__technologiesListItemInner {
    position: relative;
    padding: 45px 45px 90px;
    background: #F7F7F7;
  }

  &__technologiesListItemContainer:nth-child(1) &__technologiesListItemInner {
    @media #{$tablet-and-desktop} {
      margin-top: 50px;
    }
  }

  &__technologiesListItemContainer:nth-child(2) &__technologiesListItemInner {
    @media #{$tablet-and-desktop} {
      margin-top: 25px;
      padding-bottom: 150px;
    }
  }

  &__technologiesListItemContainer:nth-child(1) &__technologiesListItemImage {
    width: 192px;
    height: 121px;
  }

  &__technologiesListItemContainer:nth-child(2) &__technologiesListItemImage {
    width: 185px;
    height: 128px;
  }

  &__technologiesListItemContainer:nth-child(3) &__technologiesListItemImage {
    width: 214px;
    height: 145px;
  }

  &__technologiesListItemTitle {
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 500;
  }

  &__technologiesListItemText {
    font-size: 16px;
    line-height: 1.6;
  }

  &__technologiesListItemNumber {
    position: absolute;
    right: 45px;
    bottom: 0;

    font-family: Shoulders, sans-serif;
    font-size: 99px;
    font-weight: 500;
    line-height: .7;
    color: #fff;
  }
}
