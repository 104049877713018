@import "../../include";

.partWhyReplace {
  @extend %container;

  &__title {
    font-family: Shoulders, sans-serif;
    font-size: 52px;
    font-weight: 900;
    line-height: 1.3;
    text-transform: uppercase;

    @media #{$phone} {
      font-size: 46px;
    }
  }

  &__subTitle {
    margin-bottom: 40px;
    font-size: 26px;
    line-height: 1.5;

    span {
      background: url("./assets/underline.svg") no-repeat center bottom / 100% auto;
    }

    @media #{$phone} {
      font-size: 20px;
    }
  }

  &__causesList {
    display: flex;
    margin-right: -5px;
    margin-left: -5px;

    @media #{$phone} {
      flex-wrap: wrap;
      margin-right: -3px;
      margin-left: -3px;
    }
  }

  &__causesListItemContainer {
    flex: 0 0 25%;
    padding-right: 5px;
    padding-left: 5px;

    @media #{$tablet-and-desktop} {
      &:nth-child(1) {
        padding-top: 40px;}

      &:nth-child(2) {
        padding-top: 20px;
      }

      &:nth-child(3) {
        padding-top: 60px;
      }
    }


    @media #{$phone} {
      flex: 0 0 50%;
      padding-right: 3px;
      padding-left: 3px;


      &:nth-child(n + 3) {
        margin-top: 20px;
      }
    }
  }

  &__causesListItem {
    position: relative;
  }

  &__causesListItemIcon {
    position: absolute;
    top: 20px;
    left: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 45px;
    height: 45px;

    background: #FEFF89;
    border-radius: 5px;
  }

  &__causesListItemContainer:nth-child(1) &__causesListItemIcon svg {
    width: 25px;
    height: 25px;
  }

  &__causesListItemContainer:nth-child(2) &__causesListItemIcon svg {
    width: 28px;
    height: 28px;
  }

  &__causesListItemContainer:nth-child(3) &__causesListItemIcon svg {
    width: 29px;
    height: 29px;
  }

  &__causesListItemContainer:nth-child(4) &__causesListItemIcon svg {
    width: 29px;
    height: 29px;
  }

  &__causesListItemImage {
    z-index: -1; // to be under icon, image placeholder is position: relative
    width: 100%;
  }

  &__causesListItemTitle {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 20px;

    font-size: 26px;
    font-weight: 500;
    line-height: 1.3;
    color: #fff;
    white-space: pre-line;

    @media #{$phone} {
      font-size: 20px;
    }
  }
}
