@import "../../include";

.partWindowViewSlider {
  @extend %container-full;

  position: relative;

  &__title {
    margin-bottom: 40px;

    font-family: Shoulders, sans-serif;
    font-size: 74px;
    font-weight: 900;
    line-height: 1.1;
    text-align: center;
    text-transform: uppercase;

    @media #{$phone} {
      font-size: 46px;
    }
  }

  &__window {
    position: relative;
    aspect-ratio: 1812 / 650;

    @media #{$phone} {
      aspect-ratio: 10 / 7;
    }
  }

  &__windowImage {
    position: absolute;
    top: 0;
    bottom: 0;

    width: 0;

    transition: 1s;

    &_1 {
      right: 0;
    }

    &_2 {
      left: 0;
    }

    &_visible {
      z-index: 1;
      width: 100%;
    }

    img {
      object-fit: cover;
    }
  }

  &__sliderToggleButtonsContainer {
    position: absolute;
    z-index: 1;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    @media #{$phone} {
      bottom: -20px;
    }
  }

  &__toggleButton {
    cursor: pointer;

    display: flex;
    align-items: center;

    padding: 24px 70px;

    font-size: 16px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    white-space: nowrap;

    background: #FF6D3F;
    border: #FF6D3F;
    border-radius: 4px;
    box-shadow: 0 27px 56px rgb(255 109 63 / 43%), 0 8.14px 16.88px rgb(255 109 63 / 28%), 0 3.38px 7.01px rgb(255 109 63 / 21.5%), 0 1.22px 2.54px rgb(255 109 63 / 15%), inset 0 12px 28px rgb(255 221 102 / 45%);
  }

  &__seeDifferenceButton {
    &:hover {
      box-shadow: none;
    }

    svg {
      margin-right: 10px;
    }
  }

  &__backButton {
    padding: 1.45rem;
    background: #FFF;
    border: 2px solid #fff;

    &:hover {
      border-color: #FF6D3F;
      box-shadow: none;
    }
  }
}
